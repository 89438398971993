import React, { useState, Fragment, useCallback, useEffect } from "react";
import styled from "styled-components";
import DefaultPageLayout from "../components/DefaultPageLayout";
import SEO from "../components/seo";
import { useWaypoints, WaypointManager } from "../components/useWaypoint";
import { Link } from "gatsby"

import InternetCards from "../images/InternetCards.svg";
import KnowAnyone from "../images/KnowAnyone.svg";
import Crumbs from "../images/Crumbs.png";
import OGImage from "../images/og.png";
import Logo from "../images/tbcny.svg";
import MailMobileFrontPageIcon from "../images/MailMobileFrontPageIcon.svg";
import MobileMenuIcon from "../images/MobileMenu.svg";
import { useSpring, animated } from "react-spring";
import BrowserFrame, { WINDOW_HEIGHT_VH } from "../components/BrowserFrame";
import StoryHintOverlay from "../components/StoryHintOverlay";
import Helmet from "react-helmet";

import LinkedInCursor from "../images/LinkedInCursor.png";
import MailCursor from "../images/MailCursor.png";
import NateCursor from "../images/NateCursor.png";
import TwitterCursor from "../images/TwitterCursor.png";
import BaseCursor from "../images/BaseCursor.png";

import { ARC_NET_URL, HELLO_EMAIL, JOBS_URL, NEWSLETTER_URL, WAITLIST_FORM_URL } from "../components/Constants";
import GlobalHeader from "../components/GlobalHeader";
import { useWindowSize } from "../components/useScreenSize";
import StoryControl, { StoryPage } from "../components/StoryControl";
import MobileMenuModal from "../components/MobileMenuModal";
import { EmailListModalLink } from "../components/EmailListModal";
import FauxWindow from "../components/FauxWindow";
import Constellation from "../components/Constellation";
import StayInTouchField from "../components/StayInTouchField";
import ConstellationMobile from "../components/ConstellationMobile";
import BrowserImageryBottomOverlay from "../components/BrowserImageryBottomOverlay";
import { AspectRatioImage, BlueSection } from "../components/Elements";
import JoinWaitlistButton from "../components/JoinWaitlistButton";
import GetArcButton from "../components/GetArcButton";

const SITE_URL = "https://thebrowser.company";

const DesktopBrowserPagesTopAndBottomSpacing = styled.div`
  height: ${(100 - WINDOW_HEIGHT_VH.mobile) / 2}vh;
  @media screen and (min-width: ${WINDOW_HEIGHT_VH.breakpoint}px) {
    height: ${(100 - WINDOW_HEIGHT_VH.desktop) / 2}vh;
  }
`;

const WhiteSection = styled.div`
  display: flex;
  width: 100%;

  min-height: ${WINDOW_HEIGHT_VH.mobile}vh;
  @media screen and (min-width: ${WINDOW_HEIGHT_VH.breakpoint}px) {
    min-height: ${WINDOW_HEIGHT_VH.desktop}vh;
  }

  flex-direction: column;
  align-items: center;
  justify-content: center;
  // padding: 2rem;
  padding: 2rem 0;
  box-sizing: border-box;
  color: #c9cdda;

  position: relative;
`;

const Header = styled.h1`
  user-select: none;
  width: 100%;
  // max-width: 800px;
  line-height: 1.3;
  font-size: 1.6rem;
  @media screen and (min-width: 600px) {
    font-size: 2rem;
  }
  @media screen and (min-width: 800px) {
    font-size: 2.3rem;
  }
  // @media screen and (min-width: 1000px) {
  //     font-size: 2.7rem;
  // }
  text-align: center;
  font-weight: 700;
`;

const SmallHeader = styled.h2`
  width: 100%;
  user-select: none;
  // max-width: 800px;
  line-height: 1.3;
  font-size: 1.5rem;
`;

const DesktopOnlyDivider = styled.div`
  @media screen and (min-width: 600px) {
    height: 2.5rem;
  }
`;

const KnowAnyoneContainer = styled.div`
  width: 7rem;
  margin-top: 0.5rem;
  transition: 0.1s transform ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;

const TextMarginsForFittingWithinBrowserWindow = styled.div`
  align-self: stretch;
  padding-top: 2rem;
  margin-left: 12vw;
  margin-right: 12vw;
  @media screen and (min-width: 1000px) {
    margin-left: 20vw;
    margin-right: 20vw;
  }
  @media screen and (min-width: 1200px) {
    margin-left: auto;
    margin-right: auto;
    width: 800px;
  }
`;

const TextMargins = styled(TextMarginsForFittingWithinBrowserWindow)`
  padding-top: 0;
`;

// const TextMargins = styled.div`
//     margin-left: 1rem;
//     margin-right: 1rem;
//     @media screen and (min-width: 1000px) {
//         margin-left: auto;
//         margin-right: auto;
//         width: 800px;
//     }
// `;

const BlueStoryPage = styled.div<{ bgColor: string }>`
  background-color: ${(props) => props.bgColor};
  box-sizing: border-box;
  color: white;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  min-height: 100%;
`;

const WhiteStoryPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  height: 100%;
  color: #c9cdda;
`;

const BCNY = styled.span`
  white-space: nowrap;
`;

const Highlight = styled.span`
  background-color: rgba(255, 255, 255, 0.25);
`;

const HighlightStrong = styled.strong`
background-color: rgba(255, 255, 255, 0.25);
`;

const Job = ({ children, link }) => (
  <li style={{ opacity: 0.5 }}>
    <AWithCursor cursor={link ? BaseCursor : MailCursor} href={ link || "mailto:hello@thebrowser.company" }>
      {children}
    </AWithCursor>
  </li>
);

const SuperscriptLink = styled.a`
  font-size: 0.6em;
  vertical-align: top;
  color: white;
`;

const AWithCursor = styled.a<{ cursor?: string }>`
  ${(props) => (props.cursor ? `cursor: url(${props.cursor}), pointer;` : ``)};
`;

const LocalLink = styled(props => <Link {...props} />)`
  background-color: rgba(255, 255, 255, 0.25);
  color: inherit;
`;

const BodyA = styled(AWithCursor)`
  background-color: rgba(255, 255, 255, 0.25);
  color: inherit;
`;

const LargeParagraph = styled.p`
  line-height: 1.5;
  font-size: 1rem;
  font-weight: 500;
  opacity: 0.8;
  @media screen and (max-width: 600px) {
    font-size: 1.2rem;
  }
`;

const JobsList = styled.ul<{ cursor?: string }>`
  margin: 0;
  font-weight: 500;
  fontsize: 1rem;
  padding: 0;
  @media screen and (max-width: 600px) {
    font-size: 1.2rem;
  }
  a {
    color: inherit;
  }
`;

const LogoContainer = styled.div`
  width: 80%;
  align-self: center;
  margin: auto;
  max-width: 280px;
  opacity: 0.33;
`;

const Root = styled.div`
  width: 100%;
  height: 100%;
`;

const Superscript = styled.sup``;

const EmailIconA = styled.a`
  position: absolute;
  display: block;
  padding: 1rem;
  top: 0;
  right: 0;
  z-index: 500;
`;
const EmailIcon = () => (
  <EmailIconA href={HELLO_EMAIL}>
    <img src={MailMobileFrontPageIcon} />
  </EmailIconA>
);

const MenuButton = styled.div`
  position: absolute;
  display: block;
  padding: 1rem;
  top: 0;
  right: 0;
  z-index: 500;
`;

const FooterLinks = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  > * {
    padding: 0.5em;
    color: white;
    opacity: 0.5;
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const WaitlistButtonContainer = styled.div`
  align-items: center;
  position: fixed;
  bottom: 0;
  z-index: 100;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  bottom: 32px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

interface MenuEntryPointProps {
  switchToPage(id: string): void;
}
const MenuEntryPoint = ({switchToPage}: MenuEntryPointProps) => {
  const [showingMenu, setShowingMenu] = useState(false);
  const hideMenu = useCallback(() => { setShowingMenu(false) }, []);
  const showMenu = useCallback(() => { setShowingMenu(true) }, []);
  if (showingMenu) {
    return <MobileMenuModal onHide={hideMenu} switchToPage={switchToPage} />;
  }
  return <MenuButton onClick={showMenu}><img src={MobileMenuIcon} /></MenuButton>
}

const AnimatedFrame = animated(BrowserFrame);

interface StoryPageDecorations {
  tabCount: number;
  browserOverlayOpacity: number;
}

interface BrowserStoryPage extends StoryPage {
  decorations: StoryPageDecorations;
}

interface PageRenderOptions {
  tabCountWaypoints?: WaypointManager<number>; // desktop only
}

interface HybridPage {
  decorations?: Partial<StoryPageDecorations>;
  kind: "white" | "blue" | "darkBlue" | "raw" | "black";
  render(options?: PageRenderOptions): React.ReactNode;
  desktopOnly?: boolean;
  mobileOnly?: boolean;
  id?: string;
}

function bgColorForColoredPage(kind: HybridPage['kind']): string | undefined {
  return {'blue': '#0C50FF', 'darkBlue': '#0C43D1', 'black': "#000"}[kind];
}

const MobileSite = ({ pages }: { pages: HybridPage[] }) => {

  function hybridPageToStoryPage(
    { kind, decorations, render, id }: HybridPage,
    baseDecorations: StoryPageDecorations,
    index: number
  ): BrowserStoryPage {
    const finalId = id || `${index}`;
    if (kind === "raw") {
      return {
        decorations: { ...baseDecorations, ...decorations },
        render,
        id: finalId,
      };
    }
    if (bgColorForColoredPage(kind)) {
      return {
        decorations: { ...baseDecorations, ...decorations },
        render: () => (
          <BlueStoryPage bgColor={bgColorForColoredPage(kind)}>
            <TextMargins>{render()}</TextMargins>
          </BlueStoryPage>
        ),
        id: finalId,
      };
    }
    return {
      id: finalId,
      decorations: { ...baseDecorations, ...decorations },
      render: () => (
        <WhiteStoryPage>
          <TextMarginsForFittingWithinBrowserWindow>
            {render()}
          </TextMarginsForFittingWithinBrowserWindow>
        </WhiteStoryPage>
      ),
    };
  }
  let decorations: StoryPageDecorations = {
    browserOverlayOpacity: 1,
    tabCount: 1,
  };
  const storyPages: BrowserStoryPage[] = pages
    .filter((page) => !page.desktopOnly)
    .map((page, index) => {
      const storyPage = hybridPageToStoryPage(page, decorations, index);
      decorations = storyPage.decorations;
      return storyPage;
    });

  const initialPageId = (() => {
    // If the page's hash matches the id of a page, select it as the initial page.
    if (location.hash.startsWith('#')) {
      const hash = location.hash.substring(1);
      for (const page of storyPages) {
        if (page.id === hash) {
          return page.id;
        }
      }
      return storyPages[0].id;
    }
    return storyPages[0].id;
  })();

  const [pageId, setPageId] = useState(initialPageId);
  const isFirstPage = pageId === storyPages[0].id;
  const isLastPage = pageId === storyPages[storyPages.length - 1].id;

  const page = storyPages.find(page => page.id === pageId)

  // useEffect(() => {
  //   if (page) {
  //     setTabCount(page.decorations.tabCount);
  //     setBrowserOverlayOpacity(page.decorations.browserOverlayOpacity);
  //   }
  // }, [page]);
  const {tabCount, browserOverlayOpacity} = page.decorations;
  const tabCountAnimated = useSpring({ x: tabCount }).x;

  return (
    <Root>
      <StoryHintOverlay canGoLeft={!isFirstPage} canGoRight={!isLastPage} />
      <AnimatedFrame
        tabCount={tabCountAnimated}
        opacity={browserOverlayOpacity}
      />
      <StoryControl
        pages={storyPages}
        pageId={pageId}
        setPageId={setPageId}
      />
      <MenuEntryPoint switchToPage={setPageId} />
       {Number(pageId) <= 13 && (
        <WaitlistButtonContainer>
          <GetArcButton />
        </WaitlistButtonContainer>
      )}
    </Root>
  );
};

const DesktopSite = ({ pages }: { pages: HybridPage[] }) => {
  const [tabCount, setTabCount] = useState<number>(1);
  const tabCountWaypoints = useWaypoints<number>((status) => {
    setTabCount(status.from);
  });
  const [browserOverlayOpacity, setBrowserOverlayOpacity] = useState<number>(1);
  const browserOverlayOpacityWaypoints = useWaypoints<number>((status) => {
    setBrowserOverlayOpacity(status.from);
  });
  const tabCountAnimated = useSpring({ x: tabCount }).x;

  const renderOptions: PageRenderOptions = {
    tabCountWaypoints,
  };

  function renderHybridPageAsBlock(
    { kind, decorations, render, id }: HybridPage,
    key: any,
    index: number
  ): React.ReactNode {
    const finalId = id || `${index}`;
    const waypoints = (
      <Fragment key="waypoints">
        <div id={finalId} />
        {decorations?.tabCount !== undefined
          ? tabCountWaypoints.renderWaypoint(decorations.tabCount, -70)
          : undefined}
        {decorations?.browserOverlayOpacity !== undefined
          ? browserOverlayOpacityWaypoints.renderWaypoint(
              decorations.browserOverlayOpacity,
              -70
            )
          : undefined}
      </Fragment>
    );
    if (kind === "raw") {
      return (
        <Fragment key={key}>
          {waypoints}
          {render(renderOptions)}
        </Fragment>
      );
    }
    if (kind === "white") {
      return (
        <WhiteSection key={key}>
          {waypoints}
          <TextMarginsForFittingWithinBrowserWindow>
            {render(renderOptions)}
          </TextMarginsForFittingWithinBrowserWindow>
        </WhiteSection>
      );
    }
    return (
      <BlueSection key={key} bgColor={bgColorForColoredPage(kind) || "#000"}>
        {waypoints}
        <TextMargins>{render(renderOptions)}</TextMargins>
      </BlueSection>
    );
  }
  return (
    <Root>
      <AnimatedFrame
        tabCount={tabCountAnimated}
        opacity={browserOverlayOpacity}
      />
      <GlobalHeader bgColor="rgba(19,18,21,0.9)" fgColor="#C9CDDA" />
      {pages
        .filter((page) => !page.mobileOnly)
        .map((page, i) => renderHybridPageAsBlock(page, i, i))}
    </Root>
  );
};

const FooterContent: HybridPage[] = [
  {
    kind: "blue",
    desktopOnly: true,
    render: () => (
      <>
        <DesktopBrowserPagesTopAndBottomSpacing />
      </>
    ),
  },
  {
    kind: "darkBlue",
    desktopOnly: true,
    render: () => (
      <>
        <DesktopBrowserPagesTopAndBottomSpacing />
        <LogoContainer>
          <AspectRatioImage src={Logo} aspect={1.44598} />
        </LogoContainer>
        <DesktopBrowserPagesTopAndBottomSpacing />
      </>
    ),
  },
  {
    kind: "darkBlue",
    mobileOnly: true,
    render: () => (
      <LogoContainer>
        <AspectRatioImage src={Logo} aspect={1.44598} />
      </LogoContainer>
    ),
  },
  {
    kind: "black",
    render: () => (
      <FooterLinks>
        <a href="https://arc.net/privacy">Privacy Policy</a>
        <a href="https://arc.net/security">Security</a>
        <span>Hi there :)</span>
      </FooterLinks>
    )
  }
];

const Index = () => {
  const pages: HybridPage[] = [
    {
      kind: "raw",
      render: () => <DesktopBrowserPagesTopAndBottomSpacing />,
      desktopOnly: true,
    },
    {
      decorations: { tabCount: 1, browserOverlayOpacity: 1 },
      kind: "white",
      render: () => (
        <>
          <Header>This is a web&nbsp;browser.</Header>
        </>
      ),
    },
    {
      decorations: { tabCount: 2 },
      kind: "white",
      render: () => (
        <Header>It’s&nbsp;just a frame, with some buttons and toolbars.</Header>
      ),
    },
    {
      decorations: { tabCount: 3 },
      kind: "white",
      render: () => (
        <Header>It’s our window to everything on the internet.</Header>
      ),
    },
    {
      decorations: { tabCount: 4 },
      kind: "white",
      desktopOnly: true,
      render: (options) => (
        <div style={{ maxWidth: "100vw" }}>
          <Constellation>
            <div style={{ position: "absolute", top: "5%", left: 0 }}>
              {" "}
              {options?.tabCountWaypoints?.renderWaypoint(5, -70)}{" "}
            </div>
            <div style={{ position: "absolute", top: "27%", left: 0 }}>
              {" "}
              {options?.tabCountWaypoints?.renderWaypoint(6, -70)}{" "}
            </div>
            <div style={{ position: "absolute", top: "48%", left: 0 }}>
              {" "}
              {options?.tabCountWaypoints?.renderWaypoint(7, -70)}{" "}
            </div>
            <div style={{ position: "absolute", top: "70%", left: 0 }}>
              {" "}
              {options?.tabCountWaypoints?.renderWaypoint(8, -70)}{" "}
            </div>
          </Constellation>
        </div>
      ),
    },
    ...[0, 1, 2].map((i) => ({
      decorations: { tabCount: 4 + i },
      kind: "raw",
      mobileOnly: true,
      render: () => <ConstellationMobile page={i} />,
    })),
    {
      kind: "white",
      render: () => (
        <Header>We love the internet, but it can be overwhelming.</Header>
      ),
    },
    {
      kind: "white",
      render: () => (
        <Header>
          What if a browser could help us make sense of it&nbsp;all?
        </Header>
      ),
    },
    {
      kind: "white",
      decorations: { browserOverlayOpacity: 0 },
      mobileOnly: true,
      render: () => (
        <>
          <BrowserImageryBottomOverlay />
          <Header>
            Could a browser keep us focused, organized and in&nbsp;control?
          </Header>
        </>
      ),
    },
    {
      kind: "white",
      desktopOnly: true,
      render: () => (
        <>
          <Header>
            Could a browser keep us focused, organized and in&nbsp;control?
          </Header>
        </>
      ),
    },
    {
      kind: "raw",
      desktopOnly: true,
      render: () => <AspectRatioImage aspect={8.3934} src={Crumbs} />,
    },
    {
      decorations: { browserOverlayOpacity: 0 },
      kind: "blue",
      render: () => (
        <>
          <DesktopOnlyDivider />
          <SmallHeader>
            At{" "}
            <BCNY>
              <Highlight>the Browser Company</Highlight>
            </BCNY>
            , we’re building a better way to use the internet.
          </SmallHeader>
        </>
      ),
    },
    {
      kind: "blue",
      render: () => (
        <LargeParagraph>
          When we think about browsing the internet, we often ignore the browser
          itself. Instead, we think about all the things we do online—the
          articles we read, the emails we send, the shows we watch. The software
          that enables it all, the browser, is so ubiquitous that it’s ignored.
        </LargeParagraph>
      ),
    },
    {
      kind: "blue",
      render: () => (
        <LargeParagraph>
          Despite the ways our internet use has evolved, the browser has
          remained relatively unchanged. While all of our other software tools
          are changing for the better—with more collaborative features, flexible
          interfaces, and powerful functionality—the browser largely still does
          what it did twenty-five years ago.
        </LargeParagraph>
      ),
    },
    {
      kind: "blue",
      render: () => (
        <LargeParagraph>
          What’s more, we blame ourselves for the browser’s shortcomings. When
          our browser has an overwhelming number of tabs, we call ourselves
          inefficient; when we get distracted online, we tell ourselves we need
          more discipline; when we can’t find a url, we think we’re
          disorganized. Why does the browser get a free pass?
        </LargeParagraph>
      ),
    },
    {
      kind: "blue",
      render: () => (
        <LargeParagraph>
            That’s why we’re building a new browser, {" "}
            <BodyA href={ARC_NET_URL}>which we call Arc,</BodyA>{" "}
            because we believe browsers can do so much more to empower us. 
            We’re imagining a browser that can think as quickly as we do, take work off of our plates, and pull our creativity forward. 
            A browser equipped for the way we use the internet in {new Date().getFullYear()}, 
            and foundational for how we hope to use it in the future.
        </LargeParagraph>
      ),
    },
    {
      kind: "blue",
      render: () => (
        <>
          <h1>Our Team</h1>
          <LargeParagraph>
            We’re a team of founders who sold their last company, ex-Instagram
            engineers, former Heads of Design at Tesla and Medium, multiple Google Chrome
            alums, alumni from Snap, Slack and Pinterest, and so
            many other people who have done it before.{" "}
            <LocalLink to={JOBS_URL}>
              Work with us →
            </LocalLink>
          </LargeParagraph>
        </>
      )
    },
    {
      kind: "blue",
      render: () => (
        <>
          <h1>Support</h1>
          <LargeParagraph>
            Beyond our team, we’re lucky to be supported by our family and friends,
            as well as investors who played a role in some of our favorite software
            companies over the years. We’ve raised over $17 million dollars from a
            diverse group that includes the founders of Instagram, Stripe, Twitter,
            Zoom, Figma, and LinkedIn. We’re always grateful for new perspectives and shared
            learnings from experience. If you’d like to lend a hand,{" "}
            <BodyA href={HELLO_EMAIL} cursor={MailCursor}>
              please send us a note
            </BodyA>
            .
          </LargeParagraph>
        </>
      ),
    },
    {
      kind: "blue",
      render: () => (
        <>
          <LargeParagraph>
            Otherwise, feel free to{" "}
            <BodyA href={NEWSLETTER_URL}>
            join our friends and family newsletter
            </BodyA>
            , and
            we promise you’ll be among the first to play with our new browser.
          </LargeParagraph>
          <GetArcButton />
        </>
      ),
    },
    ...FooterContent,
  ];

  const { innerWidth } = useWindowSize();
  const site =
    innerWidth < 720 ? (
      <MobileSite key="mobile" pages={pages} />
    ) : (
      <DesktopSite key="desktop" pages={pages} />
    );
  return (
    <Root>
      <SEO 
        title="The Browser Company | Building Arc" 
        description={"We’re building a better way to use the internet, starting with your browser. Let go of the clicks, clutter, and distraction with Arc."}
        />
      <Helmet>
        <meta property="og:image" content={`${SITE_URL}${OGImage}`} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="643" />
        <html className="dark" />
      </Helmet>
      <DefaultPageLayout>
        {site}
      </DefaultPageLayout>
    </Root>
  );
};

export default Index;
