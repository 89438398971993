import React from 'react';
import styled, { css } from 'styled-components';
import { ARC_NET_URL, DIA_URL } from '../Constants';
import { IconArc, IconDia, IconLeftArrow } from './Icons';
import { Link } from 'gatsby';

// Header breakpoint differs from mobile to accommodate when the buttons hit the logo
const headerBreakpoint = (...args) => css`
  @media screen and (min-width: 35.625rem) {
    ${css(...args)}
  }
`;

const CareersHeaderContainer = styled.section`
  display: flex;
  flex-row: column;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;

  row-gap: 1.25rem;
  ${headerBreakpoint`
    max-width: 67.5rem;
    padding: 3.125rem 2.5rem 0 2.5rem;
    row-gap: 3.125rem;
  `};
`;

const HeaderLeft = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  gap: 1rem;
  color: var(--primary-body);

  font-family: Nunito;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.02rem;

  order: 3;
  justify-content: center;
  ${headerBreakpoint`
    order: 1;
    justify-content: flex-start;
  `};
`;

const HeaderRight = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  gap: 1rem;
  order: 2;

  width: 100%;
  justify-content: center;
  ${headerBreakpoint`
    width: auto;
    justify-content: flex-end;
  `};
`;

export const HeaderButton = styled.a`
  display: flex;
  padding: 0 1.875rem 0 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border-radius: 0.875rem;
  height: 3.375rem;
  text-decoration: none;

  font-family: Nunito;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.02rem;
  transition: background-color 0.2s;

  ${({ color }) => color && `color: ${color};`}
  ${({ bgColor }) => bgColor && `background-color: ${bgColor};`}
  ${({ bgColorHover }) => bgColorHover && css`
    &:hover {
      background-color: ${bgColorHover};
    }`
  }
`;

const BackToMainWrapper = styled.div`
  width: 100%;
  flex-shrink: 0;
  display: flex;
  
  order: 1;
  background: rgba(0, 71, 255, 0.10);
  justify-content: center;
  ${headerBreakpoint`
    order: 3;
    background: none;
    justify-content: start;
  `};
`;

const BackToMainButton = styled((props) => <Link {...props} />)`
  color: var(--internet-blue);

  display: flex;
  align-items: center;
  gap: 0.75rem;

  & svg {
    opacity: 0.5;
    margin-top: -2px;
    transition: opacity 0.2s, transform 0.2s;
  }

  &:hover svg {
    opacity: 1;
    transform: translateX(-2px);
  }

  font-family: Nunito;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.02rem;
  text-decoration: none;

  padding: 1.5rem 0;
  ${headerBreakpoint`
    padding: 0;
  `};
`;

export const CareersHeader = () => {
  return (
    <CareersHeaderContainer>
      <HeaderLeft>
        {/* BCNY logo */}
        <svg width="34" height="37" viewBox="0 0 34 37" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.75 18.113V18.363H1H7.92418C9.76473 18.363 11.4479 17.9367 12.6793 17.1202C13.9176 16.2991 14.6993 15.0805 14.6993 13.5305C14.6993 12.2913 14.2323 11.2469 13.4049 10.4554C12.7811 9.85878 11.9596 9.41199 10.9925 9.12688C12.963 8.44308 14.2802 7.06679 14.2802 5.17281C14.2802 3.75568 13.5702 2.62861 12.4554 1.86523C11.3467 1.10599 9.83737 0.703613 8.20354 0.703613H1H0.75V0.953613V1.2968V1.53194L0.9847 1.54633C1.48276 1.57687 1.85545 1.60028 2.14235 1.64339C2.43159 1.68684 2.59597 1.74575 2.69847 1.82247C2.78993 1.89092 2.85787 1.99326 2.90264 2.19429C2.94937 2.40413 2.96494 2.69486 2.96494 3.11368V15.953C2.96494 16.3718 2.94937 16.6625 2.90264 16.8724C2.85787 17.0734 2.78993 17.1757 2.69847 17.2442C2.59597 17.3209 2.43159 17.3798 2.14235 17.4233C1.85545 17.4664 1.48276 17.4898 0.9847 17.5203L0.75 17.5347V17.7698V18.113ZM4.92161 17.4779V9.62182H8.04391C9.60393 9.62182 10.7165 9.99684 11.4377 10.652C12.1548 11.3036 12.5231 12.2674 12.5231 13.5305C12.5231 14.7913 12.1462 15.754 11.4009 16.4086C10.6497 17.0683 9.48565 17.4494 7.84286 17.4593L7.84277 17.4593L4.92161 17.4779ZM8.08204 8.69789L4.92161 8.71659V1.60737H8.02395C9.45241 1.60737 10.4653 1.96128 11.1197 2.56334C11.7709 3.16243 12.1041 4.04146 12.1041 5.17281C12.1041 6.32309 11.7617 7.18608 11.1144 7.76768C10.4624 8.35353 9.46316 8.68805 8.08204 8.69789Z" fill="currentColor" stroke="currentColor" strokeWidth="0.5"/>
          <path d="M33.1738 13.3442L33.2686 13.0935L33.0119 13.0163L32.6114 12.8959L32.3913 12.8297L32.3069 13.0435C31.7693 14.4054 31.0194 15.4545 30.0078 16.1648C28.9969 16.8745 27.7033 17.261 26.0513 17.261C23.7331 17.261 21.9617 16.321 20.7651 14.8658C19.564 13.4052 18.9321 11.412 18.9321 9.30158C18.9321 6.98129 19.5137 4.9373 20.6303 3.47969C21.7408 2.02989 23.394 1.14143 25.5907 1.14143C29.2497 1.14143 31.9178 3.49815 32.4138 6.156L32.4519 6.36014H32.6596H33H33.2571L33.2499 6.1031L33.1097 1.12526L33.1029 0.882293H32.8598H32.4994H32.2749L32.2508 1.10548C32.2128 1.45815 32.1347 1.67518 32.0476 1.79817C31.9701 1.90753 31.8847 1.9461 31.7785 1.9461C31.5111 1.9461 31.3069 1.84112 30.9139 1.63376L30.9128 1.63319C29.9002 1.10539 28.1346 0.23999 25.6308 0.23999C20.5637 0.23999 16.75 4.02793 16.75 9.30158C16.75 14.5131 20.4217 18.3632 25.7309 18.3632C27.6244 18.3632 29.1885 17.9061 30.4319 17.0413C31.675 16.1767 32.5788 14.9174 33.1738 13.3442Z" fill="currentColor" stroke="currentColor" strokeWidth="0.5"/>
          <path d="M0.75 35.5104V35.7604H1H5.27937H5.52937V35.5104V35.2135V34.9782L5.29449 34.9639C4.85805 34.9375 4.53325 34.9173 4.28363 34.8802C4.03156 34.8428 3.8937 34.7926 3.81019 34.7308C3.73809 34.6775 3.68208 34.5971 3.6442 34.429C3.60426 34.2517 3.59054 34.0038 3.59054 33.6414V22.8035L13.9755 35.6674L14.0505 35.7604H14.17H14.5717H14.8217V35.5104V22.5325C14.8217 22.1702 14.8354 21.9222 14.8754 21.745C14.9133 21.5768 14.9693 21.4965 15.0414 21.4431C15.1249 21.3814 15.2627 21.3312 15.5148 21.2938C15.7644 21.2567 16.0892 21.2365 16.5257 21.21L16.7605 21.1958V20.9605V20.6636V20.4136H16.5105H12.2312H11.9812V20.6636V20.9605V21.1958L12.2161 21.21C12.6525 21.2365 12.9773 21.2567 13.2269 21.2938C13.479 21.3312 13.6168 21.3814 13.7004 21.4431C13.7724 21.4965 13.8285 21.5768 13.8663 21.745C13.9063 21.9222 13.92 22.1702 13.92 22.5325V32.672L4.09398 20.5065L4.01893 20.4136H3.89949H1H0.75V20.6636V20.9605V21.1958L0.984876 21.21C1.42132 21.2365 1.74612 21.2567 1.99573 21.2938C2.2478 21.3312 2.38567 21.3814 2.46917 21.4431C2.54126 21.4965 2.59727 21.5768 2.63515 21.745C2.67509 21.9222 2.68881 22.1702 2.68881 22.5325V33.6414C2.68881 34.0038 2.67509 34.2517 2.63515 34.429C2.59727 34.5971 2.54126 34.6775 2.46917 34.7308C2.38567 34.7926 2.2478 34.8428 1.99573 34.8802C1.74612 34.9173 1.42132 34.9375 0.984876 34.9639L0.75 34.9782V35.2135V35.5104Z" fill="currentColor" stroke="currentColor" strokeWidth="0.5"/>
          <path d="M17.5876 20.9605V21.1947L17.8214 21.21C18.2183 21.2359 18.5288 21.2778 18.8145 21.4084C19.095 21.5366 19.3732 21.7607 19.6775 22.1883C19.6775 22.1883 19.6775 22.1884 19.6776 22.1884L24.6268 29.1623V33.6414C24.6268 34.0038 24.6131 34.2517 24.5731 34.429C24.5352 34.5971 24.4792 34.6775 24.4071 34.7308C24.3236 34.7926 24.1858 34.8428 23.9337 34.8802C23.6841 34.9173 23.3593 34.9375 22.9228 34.9639L22.688 34.9782V35.2135V35.5104V35.7604H22.938H28.0907H28.3407V35.5104V35.2135V34.9782L28.1058 34.9639C27.6694 34.9375 27.3446 34.9173 27.0949 34.8802C26.8429 34.8428 26.705 34.7926 26.6215 34.7308C26.5494 34.6775 26.4934 34.5971 26.4555 34.429C26.4156 34.2517 26.4019 34.0038 26.4019 33.6414V28.9333L30.3049 23.2688C30.3049 23.2687 30.305 23.2686 30.305 23.2685C31.0043 22.2595 31.3644 21.7708 31.6957 21.514C31.9968 21.2806 32.2776 21.2364 32.9045 21.2103L33.1441 21.2003V20.9605V20.6636V20.4136H32.8941H29.0863H28.8363V20.6636V20.9605V21.2031L29.0788 21.2104C29.5205 21.2236 29.8286 21.2434 30.0311 21.2933C30.1308 21.3178 30.1828 21.3448 30.2084 21.365C30.2266 21.3793 30.2318 21.3895 30.2349 21.4077C30.2395 21.4354 30.2384 21.4916 30.2066 21.5923C30.1752 21.6913 30.1193 21.817 30.0331 21.9763C29.8605 22.2955 29.5838 22.7177 29.1949 23.2814L29.1945 23.282L25.974 27.9813L22.0074 22.3717C22.0073 22.3716 22.0072 22.3714 22.0071 22.3713C21.994 22.3525 21.9811 22.3342 21.9685 22.3161C21.8012 22.0772 21.6731 21.8944 21.5837 21.7421C21.4845 21.5734 21.4587 21.4832 21.4587 21.4321C21.4587 21.4146 21.4613 21.412 21.467 21.4062L21.4675 21.4057C21.4816 21.3913 21.5224 21.3615 21.6232 21.3304C21.8275 21.2672 22.1766 21.2279 22.7362 21.2104L22.9784 21.2028V20.9605V20.6636V20.4136H22.7284H17.8376H17.5876V20.6636V20.9605Z" fill="currentColor" stroke="currentColor" strokeWidth="0.5"/>
        </svg>
        Careers
      </HeaderLeft>

      <HeaderRight>
        <HeaderButton
          href={DIA_URL}
          target="_blank"
          color="#FF8531"
          bgColor="#FFF3D6"
        >
          <IconDia />
          Meet Dia
        </HeaderButton>
        <HeaderButton
          href={ARC_NET_URL}
          target="_blank"
          color="#FFF"
          bgColor="#FF5A5F"
        >
          <IconArc style={{ marginTop:'-2px' }} />
          Get Arc
        </HeaderButton>
      </HeaderRight>

      <BackToMainWrapper>
        <BackToMainButton to="/">
          <IconLeftArrow />
          Back to main site
        </BackToMainButton>
      </BackToMainWrapper>
    </CareersHeaderContainer>
  )
};
