import footerLogoSrc from '../../images/footer-logo.png';
import React from 'react';
import styled, { css } from 'styled-components';
import {CAREERS_EMAIL, CAREERS_EMAIL_TEXT_ONLY } from '../Constants';

// Lets jump the footer to columns rather
// than rows a bit earlier than the other
// breakpoints.
const footerBreakpoint = (...args) => css`
  @media screen and (min-width: 54.375rem) {
    ${css(...args)}
  }
`;

const FooterWrapper = styled.footer`
  width: 100%;
  box-sizing: border-box;
  background: var(--dark-blue);
  padding: 0 2.5rem;
`;

const CareersFooterContainer = styled.section`
  display: flex;
  padding: 5rem 0;
  justify-content: space-between;
  align-items: stretch;
  align-self: stretch;
  max-width: 62.5rem;
  width: 100%;
  background: var(--dark-blue);
  color: #FFF;
  margin: 0 auto;
  position: relative;
  
  gap: 5rem;
  flex-direction: column;
  ${footerBreakpoint`
    gap: 6.25rem;
    flex-direction: row;
  `}
`;

const FooterContactUsContainer = styled.section`
  font-family: 'Source Serif Pro';
  
  font-style: normal;
  font-weight: 400;
  line-height: 1.8;


  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2.375rem;

  & p {
    margin: 0;
  }

  & ul {
    margin: 0;
    padding-left: 0;
  }

  & li {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    line-height: 1.4;

    list-style: none;
    margin-bottom: 0.625rem;
    & strong {
      opacity: 0.5;
    }

    font-size: 1rem;
    letter-spacing: 0.02rem;
    ${footerBreakpoint`
      font-size: 1.5rem;
      letter-spacing: 0.03rem;
    `}
  }

  & a {
    color: #FFF;
  }
  
  order: 1;
  font-size: 1.125rem;
  ${footerBreakpoint`
    order: 3;
    font-size: 1.25rem;
  `}
`;

const Divider = styled.div`
  width: 1px;
  background: rgba(255,255,255,0.5);
  flex-shrink: 0;

  display: none;
  ${footerBreakpoint`
    order: 2;
    display: block;
  `}
`;

const FooterLogoContainer = styled.section`
  text-align: center;
  order: 2;
  padding: 0;
  ${footerBreakpoint`
    order: 1;
    padding: 4.375rem 0;
  `}
`;


export const CareersFooter = () => {
  return (
    <FooterWrapper>
      <CareersFooterContainer>
        <FooterLogoContainer>
          <img src={footerLogoSrc} alt="Footer Logo" width="230" height="158" />
        </FooterLogoContainer>
        <Divider />
        <FooterContactUsContainer>
          <p>
            Just like any other careers page or job description, this page is
            probably not perfect — so please apply or reach out with questions
            if you’re interested!
          </p>
          <ul>
            <li>
              <strong>Questions?</strong>{' '}
              <a href={`${CAREERS_EMAIL}`}>{`${CAREERS_EMAIL_TEXT_ONLY}`}</a>
            </li>
          </ul>
        </FooterContactUsContainer>
      </CareersFooterContainer>
    </FooterWrapper>
  )
};
