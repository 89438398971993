import React from 'react';
import ReactPlayer from "react-player";
import styled from 'styled-components';
import { Link } from 'gatsby';
import { IconDownCaret } from './Icons';
import { notSmall } from '../../styles/mediaQueryMixins';

const PrimaryContentContainer = styled.section`
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 3.125rem;
`;

const Header = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  margin: 0;

  color: var(--primary-body);

  text-align: center;
  font-size: 3rem;
  line-height: 120%;
  ${notSmall`
    text-align: left;
    font-size: 3.375rem;
    line-height: 140%;
  `}
`;

const Body = styled.div`
  color: var(--primary-body);
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 400;
  line-height: 180%;

  & > p {
    margin-top: 0;    
  }
  
  & > p:last-child {
    margin-bottom: 0;
  }

  & a {
    color: var(--internet-blue);
    text-decoration: none;
    font-weight: 600;
  }

  & a:hover {
    text-decoration: underline;
  }

  font-size: 1.125rem;
  ${notSmall`
    font-size: 1.25rem;
  `}
`;

const OpenRolesButton = styled((props) => <Link {...props} />)`
  justify-self: start;
  width: auto;
  display: flex;
  padding: 1rem 1.25rem 1.125rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.875rem;
  background: rgba(49, 37, 13, 0.05);
  color: var(--primary-body);
  font-family: Nunito;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.02rem;
  text-decoration: none;

  & svg {
    opacity: 0.3;
    transition: opacity 0.2s, transform 0.2s;
  }

  &:hover svg {
    opacity: 1;
    transform: translateY(2px);
  }

  margin: 0 auto;
  ${notSmall`
    margin: 0;
  `}
`;

const VideoContainer = styled.div`
  width: 100%;
  max-width: 46.875rem; // 75%
  margin: 0 auto 2rem auto;
  aspect-ratio: 16 / 9;
  border-radius: 0.5rem;
  overflow: hidden;
`;

export const PrimaryContent = ({
  title,
  body,
}: {
  title: string;
  body?: React.ReactNode;
}) => {
  return (
    <PrimaryContentContainer>
      <Header>{title}</Header>
      <Body>
        {body}
      </Body>
      <OpenRolesButton to="#join-us">
        See open roles
        <IconDownCaret />
      </OpenRolesButton>
      <VideoContainer>
        <ReactPlayer
          url="https://www.youtube.com/watch?v=JcnDHK_Lqlc"
          controls
          width="100%"
          height="100%"
        />
      </VideoContainer>
    </PrimaryContentContainer>
  )
};
