import React, { useCallback, useState } from "react"
import styled from 'styled-components';
import { ARC_NET_URL, DIA_URL, HELLO_EMAIL, JOBS_URL, NEWSLETTER_URL, TEAM_URL, TWITTER_URL, VALUES_URL, WAITLIST_FORM_URL } from "./Constants";
import MenuXIcon from "../images/MenuX.svg";
import { Link } from "gatsby"

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;

    background-color: rgba(12, 80, 255, 0.95);
    color: white;

    font-size: large;
`;

const ExternalLink = styled.a`
    text-decoration: none;
    color: white;
    font-weight: bold;
    text-align: center;
    padding: 1em;
    opacity: 0.5;
    text-transform: uppercase;
`;

const LocalLink = styled(props => <Link {...props} />)`
    text-decoration: none;
    color: white;
    font-weight: bold;
    text-align: center;
    padding: 1em;
    opacity: 0.5;
    text-transform: uppercase;
`;

const CloseButton = styled.div`
    position: absolute;
    padding: 1rem;
    top: 0;
    right: 0;
    opacity: 0.5;
`;

interface Props {
    onHide(): void;
    switchToPage(id: string): void;
}
const MobileMenuModal = ({onHide, switchToPage}: Props) => {
    const onContainerClick = useCallback((e: React.MouseEvent) => {
        if (e.currentTarget === e.target) {
            onHide();
        }
    }, [onHide]);
    const onJobsClick = useCallback(() => {
        switchToPage('jobs');
        onHide();
    }, [switchToPage]);
    return (
    <Container onClick={onContainerClick}>
            <CloseButton onClick={onHide}>
                <img src={MenuXIcon} />
            </CloseButton>
            <ExternalLink href={VALUES_URL}>Values</ExternalLink>
            <ExternalLink href={HELLO_EMAIL}>Contact</ExternalLink>
            <LocalLink to={JOBS_URL}>Careers</LocalLink>
            <ExternalLink href={TWITTER_URL}>Twitter</ExternalLink>
            <ExternalLink href={DIA_URL}>Meet Dia</ExternalLink>
            <ExternalLink href={ARC_NET_URL} style={{opacity: 1}}>Get Arc</ExternalLink>
    </Container>
    )
}

export default MobileMenuModal;
