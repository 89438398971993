import { useStaticQuery, graphql } from "gatsby"
import { getSlugFromString } from "../components/utils";

export type TalkingPoint = {
  id: string;
  order: number;
  title: string;
  description: string;
  color: string;
  truncate: boolean;
  number: string;
  excerpt: string;
  slug: string;
}
export const useCareerTalkingPoints = (): TalkingPoint[] => {
  const { allSanityTalkingPoint } = useStaticQuery(
    graphql`
      query CareersTalkingPointsQuery {
        allSanityTalkingPoint {
          edges {
            node {
              id
              order
              title
              description
              color
              truncate
            }
          }
        }
      }
    `
  )

  const sorted = allSanityTalkingPoint.edges.sort((a, b) => a.node.order - b.node.order);
  return sorted.map(({ node }) => {
    let excerpt:string = '';
    if (node.truncate) {
      const words = node.description.split(' ');
      for (let i = 0; i < words.length; i++) {
        if ((excerpt + words[i]).length <= 64) {
          excerpt += ' ' + words[i];
        } else {
          break;
        }
      }
      excerpt += '...';
    }
    return {
      ...node,
      excerpt,
      number: String(node.order).padStart(2, '0'),
      slug: getSlugFromString(node.title),
    };
  });
}