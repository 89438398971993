import React, { useState } from "react"
import styled from 'styled-components';
import { DIA_URL, HELLO_EMAIL, JOBS_URL, NEWSLETTER_URL, TEAM_URL, TWITTER_URL, VALUES_URL } from "./Constants";
import StayInTouchField from "./StayInTouchField";
import useHidingHeader from './useHidingHeader';
import { Link } from "gatsby"
import GetArcButton from "./GetArcButton";

const HeaderDiv = styled.div`
    font-size: 16px;
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 1em;
    transition: opacity 0.2s ease-in-out;
`;

const ExternalLink = styled.a`
    text-decoration: none;
    color: inherit;
    padding: 0.5em 0.2em;
    margin-right: 1em;
    font-weight: 500;
`;

const LocalLink = styled(props => <Link {...props} />)`
    text-decoration: none;
    color: inherit;
    padding: 0.5em 0.2em;
    margin-right: 1em;
    font-weight: 500;
`;

interface Props {
    bgColor?: string;
    fgColor?: string;
}
const GlobalHeader = ({bgColor, fgColor}: Props) => {
    const headerVisible = useHidingHeader();
    return (
        <HeaderDiv style={{opacity: headerVisible ? 1 : 0, backgroundColor: bgColor || '#FCFCFF', color: fgColor || '#757575'}}>
            <ExternalLink href={VALUES_URL}>Values</ExternalLink>
            <ExternalLink href={HELLO_EMAIL}>Contact</ExternalLink>
            <LocalLink to={JOBS_URL}>Careers</LocalLink>
            <ExternalLink href={TWITTER_URL}>Twitter</ExternalLink>
            <ExternalLink href={NEWSLETTER_URL}>Newsletter</ExternalLink>
            <ExternalLink href={DIA_URL}>
                <span
                    style={{
                        display: "flex",
                        height: "100%",
                        alignItems: "center",
                        gap: "0.6rem",
                        whiteSpace: "nowrap",
                    }}
                    >
                    <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.99695 12.2647C5.18048 12.5477 4.98793 12.9463 4.65068 12.9463H3.09553C2.6244 12.9463 2.344 13.4618 2.63282 13.834C2.789 14.0353 2.95204 14.231 3.12159 14.4207C3.3633 14.6912 3.17944 15.1457 2.8167 15.1457H1.43089C0.982647 15.1457 0.696563 15.6188 0.93304 15.9996C3.08143 19.459 6.91525 21.7624 11.2871 21.7624C15.6589 21.7624 19.4927 19.459 21.6411 15.9996C21.8776 15.6188 21.5915 15.1457 21.1432 15.1457H19.7572C19.3945 15.1457 19.2106 14.6912 19.4523 14.4207C19.6219 14.231 19.7849 14.0353 19.9411 13.834C20.2299 13.4618 19.9495 12.9463 19.4784 12.9463H17.9241C17.5869 12.9463 17.3943 12.5477 17.5779 12.2647C18.3382 11.0925 18.7797 9.69435 18.7797 8.19306C18.7797 4.05517 15.4253 0.700745 11.2874 0.700745C7.1495 0.700745 3.79506 4.05517 3.79506 8.19306C3.79506 9.69435 4.23662 11.0925 4.99695 12.2647ZM16.2086 15.4242L15.5758 15.4241C15.0625 15.4139 14.8833 14.9619 15.2501 14.6027L15.7997 14.0855C16.1039 13.7993 15.8825 13.3251 15.4649 13.3251H7.10913C6.69151 13.3251 6.47013 13.7993 6.77429 14.0855L7.32391 14.6027C7.69071 14.9619 7.51151 15.4139 6.99824 15.4241L6.36547 15.4242H5.2567C4.83833 15.4242 4.61423 15.9017 4.90892 16.1987C6.56887 17.8715 8.81466 18.8982 11.2869 18.8982C13.7591 18.8982 16.0049 17.8715 17.6648 16.1987C17.9595 15.9017 17.7354 15.4242 17.3171 15.4242H16.2086Z"
                        fill="#FFD063"
                        />
                    </svg>
                    Meet Dia
                </span>
            </ExternalLink>
            <GetArcButton />
        </HeaderDiv>
    )
}

export default GlobalHeader;
