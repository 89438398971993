import React, { useEffect } from 'react';
import styled from 'styled-components';
import { notSmall } from '../../styles/mediaQueryMixins';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 1.875rem;
  padding: 1.875rem 0;
  ${notSmall`
    gap: 3.125rem;
    padding: 3.125rem 0;
  `}
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(0,0,0,0.1);

  gap: 1.875rem;
  padding-bottom: 1.875rem;
  ${notSmall`
    gap: 3.125rem;
    padding-bottom: 3.125rem;
  `}
`;

const Title = styled.h2`
  color: var(--primary-body);
  font-variant-numeric: ordinal;
  margin: 0;

  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  
  font-size: 3rem;
  line-height: 120%;
  ${notSmall`
    font-size: 3.375rem;
    line-height: 140%;
  `}
`;

const Body = styled.div`
  color: var(--primary-body);

  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 400;
  line-height: 180%;

  & p {
    margin: 0;
  }

  & a {
    color: var(--internet-blue);
  }

  font-size: 1.125rem;
  ${notSmall`
    font-size: 1.25rem;
  `}
`;

const PositionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 3.125rem;
`;

const AshbyScript = () => {
  useEffect(() => {
    const loadScript = async () => {
      const script = document.createElement("script");
      script.id = 'ashby-script';
      script.src = "https://jobs.ashbyhq.com/The%20Browser%20Company/embed?version=2";
      script.async = true;
      // Configure Ashby settings
      /* @ts-ignore */
      window.__Ashby = {
        settings: {
          verboseLogging: false,
          autoScroll: false,
          autoLoad: true,
          displayMode: "full-job-board",
          ashbyBaseJobBoardUrl: "https://jobs.ashbyhq.com/The%20Browser%20Company",
        }
      };
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    };
    loadScript();
  }, []);
  return null;
}

export const OpenPositions = () => {
  return (
    <Wrapper>
      <Header id="join-us">
        <Title>Join us.</Title>
        <Body>
          <p>
            Even if you don't see an exact role that matches your qualifications, it's worth submitting your resume to one of our Talent Pools. We always review folks in our Talent Pools when opening up new roles!
          </p>
        </Body>
      </Header>
      <PositionsContainer>
        <div id="ashby_embed"></div>
        <AshbyScript />
      </PositionsContainer>
    </Wrapper>
  )
}
