import Markdown from 'markdown-to-jsx';
import MarkdownAlt from 'react-markdown';
import { navigate } from '@reach/router';

import React, { ComponentProps, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { notSmall, medium, large } from '../../styles/mediaQueryMixins';
import { ToggleItem } from '../ToggleItem';
import { TalkingPoint } from '../../hooks/useCareerTalkingPoints';
import { IconRightArrow } from './Icons';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

const TalkingPointItemWrapper = styled.section`
  display: flex;
  flex-grow: 0;
  flex-shrink: 1;
  height: auto;

  ${({ onClick }) => onClick && `cursor: pointer;`}

  border-radius: 0.25rem;
  transition: background-color 0.2s, box-shadow 0.2s;

  flex-direction: column;
  justify-items: center;
  align-items: center;
  
  ${notSmall`
    ${({ onClick }) =>
      onClick && css`
      &:hover {
        background-color: #F2F6FF;
        box-shadow: 0 0 0 1.25rem  #F2F6FF;
      };
    `}
  `}

  ${({ variant }) => variant === "small" && css`
    gap: 0.625rem;
    ${notSmall`
      gap: 1.25rem;
      flex-direction: row;
      justify-items: start;
      align-items: start;
    `}
  `}
  
  ${({ variant }) => variant === "full" && css`
    gap: 1.5rem;
    ${large`
      gap: 1.25rem;
      flex-direction: row;
      justify-items: start;
      align-items: start;
    `}
  `}
`;

const DecorativeNumber = styled.span`
  display: inline-block;
  align-content: center;

  font-family: "Nunito";
  font-weight: 700;
  letter-spacing: 0.02rem;
  color: ${(props) => props.color ?? '#000'};

  ${notSmall`
    margin-bottom: 1.25rem;
  `}

  ${({ variant }) => variant === "small" && css`
    font-size: 1rem;
    line-height: 140%;
    ${notSmall`
      line-height: 240%;
    `}
  `}
  
  ${({ variant }) => variant === "full" && css`
    line-height: 140%;
    font-size: 1.125rem;
    ${notSmall`
      font-size: 1rem;
    `}
  `}
`;

const PointTitle = styled.h2`
  display: block;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  line-height: 140%;

  ${({ isSticky }) => isSticky && css`
    position: sticky;
    top: 0;
  `}
  
  font-size: 1.375rem;
  margin: 0;
  ${notSmall`
    font-size: 1.75rem;
    margin: 0 0.625rem 0 0;
  `}

  ${({ variant }) => variant === "small" && css`
    text-align: center;
    ${notSmall`
      text-align: left;
    `}
  `}
  
  ${({ variant }) => variant === "full" && css`
    text-align: left;
  `}
`;

const Sticky = styled.section`
  ${notSmall`
    position: sticky;
    top: 0;
  `}
`;

const TalkingPointHeaderLockup = styled.hgroup`
  scroll-margin-top: 4rem;

  ${notSmall`
    scroll-margin-top: 4.0625rem;
    top: 0;
  `}
`;

const PointBody = styled.section`
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 400;
  line-height: 180%;

  ${({ variant }) => variant === "small" && css`
    margin-top: 0;
    text-align: center;
    ${notSmall`
      text-align: left;
    `}
  `}
  
  ${({ variant }) => variant === "full" && css`
    text-align: left;
    margin-top: 0;
    ${notSmall`
      margin-top: 3rem;
    `}
  `}

  & p:first-child {
    margin-top: 0;
  }

  & ul {
    margin-left: 1.5rem;
    padding: 0;
  }
  & ul li {
    margin-bottom: 1.125rem;
  }
  & strong {
    font-weight: 600;
  }
  & a,
  & a:visited {
    color: var(--dark-blue);
  }

  margin-bottom: 1rem;
  font-size: 1.125rem;
  ${notSmall`
    margin-bottom: 0;
    font-size: 1.25rem;
  `}
`;

const TPLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  
  ${({ variant }) => variant === "small" && css`
    width: auto;
    ${notSmall`
      width: 3.125rem;
    `}
  `}
  
  ${({ variant }) => variant === "full" && css`
    gap: 1.5rem;
    width: 100%;
    ${large`
      gap: 0;
      width: 25rem;
    `}
  `}
`;

const TPRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: 0.625rem;
  align-items: center;

  ${({ variant }) => variant === "small" && css`
    ${notSmall`
      gap: 1.5rem;
      align-items: start;
    `}
  `}
  
  ${({ variant }) => variant === "full" && css`
    ${large`
      gap: 1.5rem;
      align-items: start;
    `}
  `}
`;

const ColorfulButton = styled.button`
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 0.625rem;
  position: relative;
  background: none;
  padding: 0;
  border: none;

  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 140%;
  letter-spacing: 0.02rem;

  color: ${(props) => props.color ?? '#FFF'};
  
  & svg {
    opacity: 0.5;
    ${notSmall`
      opacity: 1;
    `}
  }

  margin: 0 auto;
  ${notSmall`
    margin: 0;
  `}
`;

const TalkingPointItem = ({
  onMoreClick,
  number,
  title,
  slug,
  body,
  color = '#000',
  variant = "full",
  children,
}: {
  children?: React.ReactNode
  onMoreClick?: () => void;
  number?: string;
  title: string;
  slug: string;
  body?: string;
  color?: string;
  variant: "small" | "full";
}) => {
  const titleNode = (<PointTitle variant={variant}>{title}</PointTitle>);
  return (
    <TalkingPointItemWrapper variant={variant} onClick={onMoreClick}>
      <TPLeft variant={variant}>
        {number && (
          <DecorativeNumber
            color={color}
            variant={variant}
          >
            {number}
          </DecorativeNumber>
        )}
        {variant === 'full' && (
          <Sticky>
            <TalkingPointHeaderLockup id={variant === 'full' && slug}>
            {titleNode}
            </TalkingPointHeaderLockup>
          </Sticky>
        )}
      </TPLeft>
      <TPRight variant={variant}>
        {variant === 'small' && titleNode}
        <PointBody variant={variant}>
          {body && (
            <Markdown
              options={{
                overrides: {
                  ToggleItem: {
                    component: ToggleItem,
                  },
                  Markdown: {
                    component: MarkdownAlt,
                  },
                },
              }}
            >
              {body}
            </Markdown>
          )}
          {children}
        </PointBody>
        {onMoreClick && (
          <ColorfulButton
            color={color}
            onClick={onMoreClick}
          >
            More
            <IconRightArrow />
          </ColorfulButton>
        )}
      </TPRight>
    </TalkingPointItemWrapper>
  )
}

const TalkingPointsList = ({
  onClick,
  points,
  withDivider = false,
  withExcerpt = false,
  variant
}: {
  onClick?: (slug: string) => void;
  points: TalkingPoint[];
  withDivider?: boolean;
  withExcerpt?: boolean;
  variant: ComponentProps<typeof TalkingPointItem>['variant'];
}) => {
  return points.map((point, index) => (
    <React.Fragment key={point.id}>
      <TalkingPointItem
        variant={variant}
        onMoreClick={onClick && point.truncate
          ? () => onClick(point.slug)
          : undefined
        }
        number={point.number}
        title={point.title}
        slug={point.slug}
        body={withExcerpt && point.truncate ? point.excerpt : point.description}
        color={point.color}
      />
      {withDivider && index < points.length - 1 && (
        <div style={{ height:'1px', background:'rgba(0,0,0,.1)' }} />
      )}
    </React.Fragment>
  ));
};

const TalkingPointsInlineContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 3.125rem;
`;

const StyledSlidingPane = styled((props) => <SlidingPane {...props} />)`
  max-width: 87.5rem;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;

  font-size: 1rem;
  
  & .slide-pane__content {
    padding: 30px;
    ${notSmall`
      padding: 100px 150px;
    `}
  }

  ${notSmall`
    font-size: 1.375rem;
  `}

  ${medium`
    font-size: 1.625rem;
  `}

  ${large`
    font-size: 1.75rem;
  `}
`;

const PanelPointsList = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 6.25rem;
  
  gap: 3.75rem;
  ${notSmall`
    gap: 12.5rem;
  `}
`;

const TalkingPointsPanel = ({
  isOpen,
  points,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
  points: TalkingPoint[];
}) => (
  <StyledSlidingPane
    isOpen={isOpen}
    hideHeader
    width='95vw'
    onRequestClose={onClose}
  >
    <PanelPointsList>
      <TalkingPointsList
        variant="full"
        points={points}
      />
    </PanelPointsList>
  </StyledSlidingPane>
);

export const TalkingPointsSection = ({
  points,
  location,
}: {
  points: TalkingPoint[];
  location: Location;
}) => {
   const [activeSlug, setActiveSlug] = useState('');

   // set the initial slug as active if it is a TalkingPoint slug
   useEffect(() => {
      if (!location.hash) return;
      const normalizedHash = location.hash.slice(1);
      if (points.find((point) => point.slug === normalizedHash)) {
        setActiveSlug(location.hash.slice(1));
      }
   }, [points])

    // If we have an active slug, stop the
    // body from being able to scroll
    useEffect(() => {
      if (activeSlug !== '') {
        let slugToNavigateTo = activeSlug;
        // If you reload the page, location.hash includes the #,
        // whereas if you navigate to it, it does not. This normalizes
        // the string so we only prefix a # if needed
        if (slugToNavigateTo.charAt(0) !== '#') {
          slugToNavigateTo = `#${slugToNavigateTo}`;
        }
        navigate(slugToNavigateTo, { replace: true });
        document.body.style.overflow = 'hidden';
      } else {
        navigate(' ', { replace: true });
        document.body.style.overflow = 'unset';
      }
    }, [activeSlug]);
  
    const navigateToTalkingPoint = (slug:string) => {
      setActiveSlug(slug ?? '');
    };
  

  return (
    <>
      <TalkingPointsPanel
        isOpen={activeSlug !== ''}
        onClose={() => navigateToTalkingPoint('')}
        points={points}
      />
      <TalkingPointsInlineContainer>
        <TalkingPointsList
          variant="small"
          onClick={navigateToTalkingPoint}
          points={points}
          withDivider
          withExcerpt
        />
      </TalkingPointsInlineContainer>
    </>
  );
};
